
// env start
const BASE_URL_SUBSCRIPTION = 'https://gracenet.io'
// env end
// hidden menu start
document.addEventListener('DOMContentLoaded', () => {
  if (!document.querySelector('.burger')) {
    return false
  }

  const body = document.querySelector('body')

  const menuBtn = document.querySelector('.burger__icon')
  const hiddenMenu = document.querySelector('.burger')

  const toggleMenu = () => {
    hiddenMenu.classList.toggle('active')

    if (hiddenMenu.classList.contains('active')) {
      body.classList.add('overflow-all')
    } else {
      body.classList.remove('overflow-all')
    }
  }

  const closeMenu = () => {
    body.classList.remove('overflow-all')

    setTimeout(() => {
      hiddenMenu.classList.remove('active')
    }, 400)
  }

  const menuNav = document.querySelectorAll('.burger__menu .navigation__wrap li a')
  const menuSocial = document.querySelectorAll('.burger__menu .social-links a')
  const menuLogo = document.querySelector('.burger__menu .logo')

  menuBtn.addEventListener('click', toggleMenu)
  menuLogo.addEventListener('click', closeMenu)
  menuNav.forEach(el => el.addEventListener('click', closeMenu))
  menuSocial.forEach(el => el.addEventListener('click', closeMenu))
})

// hidden menu end

// accordion start

document.addEventListener('DOMContentLoaded', () => {
  if (!document.querySelector('.accordion')) {
    return false
  }
  let accordions = document.querySelectorAll('.accordion')
  const open = (accordion, accordionContent) => {
    closeAllDrops(accordion);
    accordion.classList.add('active');
    accordionContent.style.maxHeight = accordionContent.scrollHeight + 'px';
  };
  const close = (accordion, accordionContent) => {
    accordion.classList.remove('active');
    accordionContent.style.maxHeight = '';
  };
  const closeAllDrops = (accordion) => {
    accordions.forEach((elem) => {
      if (elem !== accordion) {
        close(elem, elem.children[1]);
      }
    })
  }
  open(accordions[0], accordions[0].children[1])
  accordions.forEach(elem => {
    let accordionHead = elem.querySelector('.accordion__head')
    let accordionContent = elem.querySelector('.accordion__content')
    accordionHead.addEventListener('click', () => {
      elem.classList.contains('active') ?
        close(elem, accordionContent) :
      open(elem, accordionContent)
    })
  })
})
// accordion end

// sticky header start

document.addEventListener('DOMContentLoaded', () => {
  if (!document.querySelector('.header')) {
    return false
  }

  const header = document.querySelector('.header')
  const main = document.querySelector('.main')
  const footer = document.querySelector('.footer')

  const listener = () => {
    let notSmallContent = main.getBoundingClientRect().height > footer.getBoundingClientRect().y * 2
    let nearFooter = footer.getBoundingClientRect().y / 2 < footer.getBoundingClientRect().height

    if (nearFooter && notSmallContent) {
      header.classList.add('out')
    } else {
      header.classList.remove('out')
    }
  }

  window.addEventListener('scroll', listener)
  listener()
})


// sticky header end

// form subscription start

document.addEventListener('DOMContentLoaded', () => {
    const applicantForm = document.getElementById('form-subscribe')
    const emailError = applicantForm.querySelector('#emailExist')
    const emailSuccess = applicantForm.querySelector('#emailSuccess')  
    const loader = applicantForm.querySelector('.loader')
    const formBtn = applicantForm.querySelector('button')
    function toggleVisibleInfoField(isShow = false, field = 'emailExist') {
        if (isShow) field.classList.add('show')
        else field.classList.remove('show')
    }

  function checkValidity(event) {
    const formNode = event.target.form
    const isValid = formNode.checkValidity()

        formBtn.disabled = !isValid
    }
    applicantForm.addEventListener("submit", (event) => {
      event.preventDefault(); // Предотвращаем стандартную отправку формы
      const honeypot = applicantForm.querySelector('input[name="name"]').value;
        if (honeypot) {
            console.log('name field is filled. Possible bot detected.');
            return;
        }
    const emailInput = applicantForm.querySelector('input[type="email"]');
    const email = emailInput.value;

      
      formBtn.disabled = true
      loader.style.opacity = '1'
    fetch(`${BASE_URL_SUBSCRIPTION}/subscription.php?email=${email}`)
      .then(response => response.json())
      .then(data => {
          if (data.status == 'error') {
              toggleVisibleInfoField(true, emailError)
              setTimeout(() => {
                  toggleVisibleInfoField(false, emailError)
              },1500)
          }
          if (data.status == 'success') {
              toggleVisibleInfoField(true, emailSuccess)
              setTimeout(() => {
                  toggleVisibleInfoField(false, emailSuccess)
              }, 1500)
              formBtn.disabled = true
              emailInput.value = ''
          }
          loader.style.opacity = '0'
      })
      .catch(error => {
        console.error('Ошибка при отправке запроса:', error);
      });
  })

  applicantForm.addEventListener('input', checkValidity)
})


// form subscription end

document.addEventListener('DOMContentLoaded', () => {
  const scrollers = document.querySelectorAll('.scroller > .scroller-inner');
  if (!scrollers?.length) return;
  const DUPLICATE_TIMES = window.innerWidth > 768 ? 3 : 1; // must be even e.g. 1,3,5,7,9,11...
  
  scrollers.forEach(scroller => {
    const scrollerContent = Array.from(scroller.children);
    for (let i = 0; i < DUPLICATE_TIMES; i++) {
      scrollerContent.forEach(item => {
        const duplicatedItem = item.cloneNode(true);
        Array.from(duplicatedItem.children).forEach(child => child.setAttribute('aria-hidden', true))
        duplicatedItem.setAttribute("aria-hidden", true);
        scroller.appendChild(duplicatedItem);
      })
    }
  })
})

document.addEventListener('DOMContentLoaded', () => {
  const featuresDesktop = document.querySelectorAll('.features__item .features__item-img_desktop')
  const featuresMobile = document.querySelectorAll('.features__item .features__item-img_mobile')
  const features = window.innerWidth > 768 ? featuresDesktop : featuresMobile

  if (!features?.length) return;

  const rootMargin = window.innerWidth > 420 ? '0px 0px -20% 0px' : '0px 0px -50% 0px'

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting && !entry.target.classList.contains('animated')) {
        entry.target.classList.add('animated')
      }
    })
  }, {
    threshold: 0,
    rootMargin
  })
  
  features.forEach(feature => observer.observe(feature))
})
